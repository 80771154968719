import React, { useState } from "react";
import {
  Button,
  Stack,
  Box,
  Grid,
  Paper,
  List,
  InputBase,
  ListItemButton,
  ListItemText,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// component

// icons
import { FiSearch } from "react-icons/fi";
import RoleDrawer from "./RoleDrawer";

// component
import ShowAccessListItem from "./ShowAccessListItem";
import UpdateDrawerForACL from "./UpdateDrawerForACL";

const CreateACL = () => {
  const theme = useTheme();
  const smallDown = useMediaQuery(theme.breakpoints.down("md"));

  const [openUpdateAclDrawer, setOpenUpdateAclDrawer] = useState(false);

  // state for acl
  const [user, setUser] = useState("");
  console.log(user);

  const [aclList] = React.useState([
    {
      title: "CEO",
      list: ["Dashboard", "Guest List", "Settings", "Access Control List"],
    },
    {
      title: "Manager",
      list: ["Dashboard", "Settings"],
    },
    {
      title: "G.M.",
      list: ["Dashboard", "Guest List"],
    },
    {
      title: "CEO Asst.",
      list: ["Dashboard", "Guest List", "Settings", "Access Control List"],
    },
    {
      title: "Asst. Manager",
      list: ["Dashboard", "Settings"],
    },
    {
      title: "H.R.",
      list: ["Dashboard", "Guest List"],
    },
  ]);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Stack
        direction="column"
        alignItems={"flex-end"}
        spacing={2}
        sx={{ width: "98%", margin: "0 auto", mt: 1 }}
      >
        <Button
          variant="contained"
          open={drawerOpen}
          onClick={() => setDrawerOpen(true)}
        >
          Add new role
        </Button>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ height: `calc(100vh - 9rem)` }}
          >
            <Paper
              elevation={10}
              sx={{
                borderRadius: 0,
                // border: "1px solid",
                borderColor: "primary.main",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Box for Margin */}
              <Box sx={{ p: 1.5 }}>
                {/* Search Input */}
                <InputBase
                  startAdornment={
                    // Start Icon of Search Field
                    <FiSearch
                      style={{
                        marginRight: 10,
                        fontSize: "1.5rem",
                        color: theme.palette.primary.main,
                      }}
                    />
                  }
                  name={"search"}
                  autoComplete="off"
                  placeholder={"Search Role"}
                  fullWidth
                  sx={{
                    py: 1,
                    px: 1.5,
                    boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.12)",
                    borderRadius: "100px",
                  }}
                  onChange={(e) => setUser(e.target.value)}
                />
              </Box>
              {/* Divider */}

              {/* 
              A List Will be added here
              with a scrollable list container.
              *
              *
              *
             
            */}
              {/*  SideMenuLists */}
              <List
                sx={{
                  // height: "75vh",
                  flex: 1,
                  overflowY: "auto",
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {/* User list for ACL */}

                {aclList.map((data, index) => {
                  if (user === "") {
                    return (
                      <React.Fragment key={index}>
                        <ListItemButton
                          variant="body1"
                          color="initial"
                          sx={{
                            width: "100%",
                            justifyContent: "flex-start",
                            "&.Mui-selected": {
                              background: theme.palette.primary.main,
                              color: "#fff",
                              "& .MuiListItemText-secondary": {
                                color: "#ffffff99",
                              },
                            },
                            "&.Mui-selected:hover": {
                              background: theme.palette.primary.main,
                            },
                          }}
                          selected={data.title === selectedItem.title}
                          // disabled={info.title === selectedItem}
                          onClick={() => {
                            setSelectedItem(data);
                            if (smallDown) {
                              setOpen(true);
                            } else {
                              setOpen(false);
                            }
                          }}
                        >
                          <ListItemText primary={data.title} />
                        </ListItemButton>
                      </React.Fragment>
                    );
                  } else if (
                    data.title.toUpperCase().includes(user.toUpperCase())
                  ) {
                    return (
                      <React.Fragment key={index}>
                        <ListItemButton
                          variant="body1"
                          color="initial"
                          sx={{
                            width: "100%",
                            justifyContent: "flex-start",
                            "&.Mui-selected": {
                              background: theme.palette.primary.main,
                              color: "#fff",
                              "& .MuiListItemText-secondary": {
                                color: "#ffffff99",
                              },
                            },
                            "&.Mui-selected:hover": {
                              background: theme.palette.primary.main,
                            },
                          }}
                          selected={data.title === selectedItem.title}
                          // disabled={info.title === selectedItem}
                          onClick={() => {
                            setSelectedItem(data);
                            if (smallDown) {
                              setOpen(true);
                            } else {
                              setOpen(false);
                            }
                          }}
                        >
                          <ListItemText primary={data.title} />
                        </ListItemButton>
                      </React.Fragment>
                    );
                  }
                })}
                {/* component for SideMenuLists */}
              </List>
            </Paper>
          </Grid>
          <Hidden smDown mdDown>
            <Grid item container direction={"column"} xs={0} sm={7} md={9}>
              <Grid
                item
                sx={{
                  height: "calc(100vh - 15rem)",
                  boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Stack
                  direction={"column"}
                  spacing={1}
                  sx={{
                    mx: "auto",
                    mt: 2,
                    width: { xs: "90%", sm: "90%", md: "70%" },
                  }}
                >
                  {selectedItem
                    ? selectedItem.list.map((data, i) => (
                        <ShowAccessListItem
                          data={data}
                          key={i}
                          drawerOpen={openUpdateAclDrawer}
                          setDrawerOpen={setOpenUpdateAclDrawer}
                        />
                      ))
                    : null}
                </Stack>
              </Grid>
              <Grid item>
                {selectedItem ? (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      width: "100%",
                      // mb: 2,
                      mt: 2,
                    }}
                  >
                    <Button variant="contained" color="error">
                      Delate Role
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() =>
                        setOpenUpdateAclDrawer(!openUpdateAclDrawer)
                      }
                    >
                      update Role
                    </Button>
                  </Stack>
                ) : null}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Stack>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogTitle>{selectedItem.title}</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={2}>
            {selectedItem
              ? selectedItem.list.map((data, i) => (
                  <Paper
                    sx={{
                      p: 1,
                      boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
                    }}
                    key={i}
                  >
                    <Typography align="center">{data}</Typography>
                  </Paper>
                ))
              : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "100%",
              // mb: 2,
              mt: 2,
            }}
          >
            <Button variant="contained" color="error">
              Delate Role
            </Button>
            <Button variant="contained" color="success">
              update Role
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <RoleDrawer open={drawerOpen} setOpen={setDrawerOpen} />
      {/* Drawer for Update ACL list. */}
      {selectedItem && (
        // <Drawer
        //   open={openUpdateAclDrawer}
        //   onClose={() => setOpenUpdateAclDrawer(!openUpdateAclDrawer)}
        // >
        //   {console.log(selectedItem)}
        //   <h1>{selectedItem.list}</h1>
        // </Drawer>
        <UpdateDrawerForACL
          open={openUpdateAclDrawer}
          setOpen={setOpenUpdateAclDrawer}
          selectedData={selectedItem}
        />
      )}
    </>
  );
};

export default CreateACL;

/**
 *
 *  Developed by noman :)
 */
