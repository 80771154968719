import React from "react";

import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { Stack } from "@mui/material";

// Data comming from API

const data = [
  {
    name: "Friday",
    Reservation: 40,
    Complete: 20,
    Seated: 12,
    Upcomming: 30,
  },
  {
    name: "Saturday",
    Reservation: 50,
    Complete: 40,
    Seated: 10,
    Upcomming: 50,
  },
  {
    name: "Sunday",
    Reservation: 70,
    Complete: 60,
    Seated: 80,
    Upcomming: 10,
  },
  {
    name: "Monday",
    Reservation: 100,
    Complete: 55,
    Seated: 52,
    Upcomming: 45,
  },
  {
    name: "Tuesday",
    Reservation: 100,
    Complete: 34,
    Seated: 20,
    Upcomming: 10,
  },
  {
    name: "Wednesday",
    Reservation: 112,
    Complete: 50,
    Seated: 14,
    Upcomming: 30,
  },
  {
    name: "Thursday",
    Reservation: 100,
    Complete: 50,
    Seated: 42,
    Upcomming: 10,
  },
];

const BarCharts = () => {
  //   console.log(ResponsiveContainer);
  const theme = useTheme();
  const MdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const MdAndDown = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(MdAndUp);
  return (
    <Box
      //style={{ width: "100%", height: 300 }}
      sx={{
        "& .recharts-default-legend": {
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "flex-start",
            md: "center",
          },
          justifyContent: "center",
          paddingLeft: "45px !important",
        },
      }}
    >
      <ResponsiveContainer
        // width={MdAndUp ? "100%" : "100%"}
        // width={"100%"}
        aspect={MdAndUp ? 3 : 1.5}
        debounce={30}
      >
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 40,
            bottom: 20,
            left: 0,
          }}
          barCategoryGap={10}
          // barGap={4}
          layout="horizontal"
          // stackOffset="expand"
        >
          <CartesianGrid strokeDasharray="5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          <Legend iconType="circle" />
          <Bar dataKey="Reservation" fill="#8884d8" barSize={15} />
          <Bar dataKey="Complete" fill="#82ca9d" barSize={15} />
          <Bar dataKey="Seated" fill="#4D79B8" barSize={15} />
          <Bar dataKey="Upcomming" fill="#B18F5A" barSize={15} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarCharts;

/**
 *
 * developed by Noman :) .
 */
