import http from "./httpService";
import { useQuery } from "react-query";

export const getProfileOverviewInfo = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/overview`);
};

export const useGetProfileOverviewInfo = (vendorId) => {
	return useQuery(
		["profile-overview", vendorId],
		() => getProfileOverviewInfo(vendorId),
		{ enabled: !!vendorId }
	);
};

export const postProfileOverviewInfo = (vendorId, data) => {
	return http.put(`/restaurant-profile/${vendorId}/overview`, data);
};

// Contact section API's

export const getContactInfo = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/contact`);
};

export const useGetContactInfo = (vendorId) => {
	return useQuery(["contact-info", vendorId], () => getContactInfo(vendorId), {
		enabled: !!vendorId,
	});
};

export const postContactInfo = (data, vendorId) => {
	return http.put(`/restaurant-profile/${vendorId}/contact`, data);
};
