import React from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

import {
  Stack,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { MdClose } from "react-icons/md";
import CustomisedInput1 from "../../components/CustomisedInput1";
const UserProfileCration = ({ open, setOpen }) => {
  const handleType = (event) => {
    setAuth(event.target.value);
  };
  const [auth, setAuth] = React.useState("Select");

  return (
    <>
      {" "}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            // drawer width
            width: { xs: "90vw", xms: "90vw", sm: "90vw", lg: "90vw" },
            maxWidth: "480px",
          },
        }}
        // drawer animation
        transitionDuration={100}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            margin: "0 auto",
            width: "100%",
            py: 1,
          }}
        >
          <Stack direction={"column"} px={2}>
            <Typography variant="header1" color="initial">
              Create a New User Profile
            </Typography>
            <Typography variant="cardLocation1" color="initial">
              Create your user from here
            </Typography>
          </Stack>

          <Stack>
            <IconButton
              // Close Drawer Icon
              onClick={() => setOpen(false)}
              size={"small"}
              sx={{
                mr: 1,
                my: { xs: 1, sm: 1.5 },
                backgroundColor: "#fff",
                color: "#F26B8C",
              }}
            >
              <MdClose />
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ margin: "0 auto", width: "95%", marginTop: "1rem" }}>
          <Typography variant="cardHeader" color="initial">
            Basic Information
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              marginTop: ".5rem",
            }}
          >
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                Email
              </Typography>
              <CustomisedInput1
                placeholder={"Enter email address"}
                sx={{ boxShadow: "none", height: "40px" }}
              />
            </Stack>
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                UserName
              </Typography>
              <CustomisedInput1
                placeholder="Enter username"
                sx={{ boxShadow: "none", height: "40px" }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                First name
              </Typography>
              <CustomisedInput1
                placeholder={"Enter first name"}
                sx={{ boxShadow: "none", height: "40px" }}
              />
            </Stack>
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                Last name
              </Typography>
              <CustomisedInput1
                placeholder="Enter last name"
                sx={{ boxShadow: "none", height: "40px" }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: 1,
            }}
          >
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                Phone number
              </Typography>
              <CustomisedInput1
                placeholder={"Enter phone number"}
                sx={{ boxShadow: "none", height: "40px" }}
              />
            </Stack>
            <Stack direction={"column"} width="100%" spacing={1}>
              <Typography variant="cardHeader" color="initial">
                Role
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={auth}
                  onChange={handleType}
                  size={"small"}
                  //   sx={{
                  //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "#FBB03B",
                  //     },
                  //     "&..MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "1px solid #FBB03B",
                  //     },
                  //   }}
                >
                  <MenuItem disabled value="Select">
                    Select
                  </MenuItem>
                  <MenuItem value={10}>CEO</MenuItem>
                  <MenuItem value={20}>ADMIN</MenuItem>
                  <MenuItem value={30}>CEO</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mt: 2,
              width: "100%",
            }}
          >
            <Checkbox />
            <Typography variant="cardLocation1" color="initial">
              Get notification from guest to remove their info from your
              guestbook, per the General Data Protection Regulation (GDPR)
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: "95%",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: "200px" }}
          >
            Create
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default UserProfileCration;
/**
 * create by md.masum
 */
