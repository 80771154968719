import React, { useContext } from "react";
import { Box, Stack, Typography, Button, Avatar, Alert } from "@mui/material";
import { IoIosEye } from "react-icons/io";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

// components
import Title from "../../../components/Title";
import CustomisedInput1 from "../../../components/CustomisedInput1";

// context
import snackContext from "../../../context/SnackProvider";

// API
import { updatePassword } from "../../../service/validations/updatePassword";
import { useChangePassword } from "../../../service/userService/useUpdatePassword";

const Account = () => {
  const [showPass, setShowPass] = React.useState(false);
  const [showPass1, setShowPass1] = React.useState(false);
  const [showPass2, setShowPass2] = React.useState(false);
  const [error, setError] = React.useState("");

  // destructure context
  const { snackPopUp } = useContext(snackContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: joiResolver(updatePassword) });
  // API call
  const { mutateAsync: updatePasswordData } = useChangePassword();

  // Form submission
  const onSubmit = async (data) => {
    setError("");
    try {
      const res = await updatePasswordData({ data });
      if (res.status === 204) {
        snackPopUp("success", "Password Updated Successfully!");
        setError();
        reset();
      }
    } catch (ex) {
      setError("");
      setError(ex.response?.data);
      reset();
    }
  };

  return (
    <>
      <Title text={"Account"} />

      <Box>
        <Stack direction={"column"} alignItems="center" width={"100%"}>
          <Avatar
            // Restaurant Profile Picture
            src="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmVzdGF1cmFudHxlbnwwfHwwfHw%3D&w=1000&q=80"
            sx={{ height: "120px", width: "120px", my: 2 }}
          />
          <Typography variant="header1" color="initial" mt={1}>
            MEJBAN RESTAURANT
          </Typography>
          <Typography variant="legend" color="initial" mt={1} mb={1}>
            Bir Uttam Rafiqul Islam Ave, Dhaka 1212
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={"column"} spacing={1} mb={1}>
              {" "}
              <Typography
                variant="tabText"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                Current Password
              </Typography>
              <CustomisedInput1
                sx={{
                  width: "95vw",
                  maxWidth: { xs: "95vw", md: "480px", sm: "480px" },
                }}
                type={showPass ? "text" : "password"}
                placeholder="Password"
                endAdornment={
                  showPass ? (
                    <IoIosEye
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass(!showPass)}
                    />
                  )
                }
                required
                {...register("currentPassword")}
              />
              {errors.currentPassword && (
                <Alert color="warning" severity="warning">
                  {errors.currentPassword?.message}
                </Alert>
              )}
            </Stack>
            <Stack direction={"column"} spacing={1} mb={1}>
              {" "}
              <Typography
                variant="tabText"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                New Password
              </Typography>
              <CustomisedInput1
                sx={{
                  width: "95vw",
                  maxWidth: { xs: "95vw", md: "480px", sm: "480px" },
                }}
                type={showPass1 ? "text" : "password"}
                placeholder="Password"
                endAdornment={
                  showPass1 ? (
                    <IoIosEye
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass1(!showPass1)}
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass1(!showPass1)}
                    />
                  )
                }
                required
                {...register("password")}
              />
              {errors.password && (
                <Alert color="warning" severity="warning">
                  {errors.password?.message}
                </Alert>
              )}
            </Stack>
            <Stack direction={"column"} spacing={1} mb={1}>
              {" "}
              <Typography
                variant="tabText"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                Confirm Password
              </Typography>
              <CustomisedInput1
                sx={{
                  width: "95vw",
                  maxWidth: { xs: "95vw", md: "480px", sm: "480px" },
                }}
                type={showPass2 ? "text" : "password"}
                placeholder="Password"
                endAdornment={
                  showPass2 ? (
                    <IoIosEye
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass2(!showPass2)}
                    />
                  ) : (
                    <BsFillEyeSlashFill
                      color="#000"
                      fontSize={"1.5rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPass2(!showPass2)}
                    />
                  )
                }
                required
                {...register("confirmPassword")}
              />
              {errors.confirmPassword && (
                <Alert color="warning" severity="warning">
                  {errors.confirmPassword?.message}
                </Alert>
              )}
            </Stack>
            {error && (
              <Alert color="error" severity="error">
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              save
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Account;

/**
 * create by md masum
 */
