import {
	Box,
	Stack,
	Typography,
	Button,
	IconButton,
	Alert,
} from "@mui/material";
import React from "react";
import { MdCall } from "react-icons/md";
import {
	FaLocationArrow,
	FaFacebook,
	FaInstagram,
	FaTwitter,
	FaSearchLocation,
} from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import CustomisedInput1 from "../../../components/CustomisedInput1";
import Title from "../../../components/Title";
import { useForm, Control, Controller } from "react-hook-form";
import { throttle } from "throttle-debounce";
import { joiResolver } from "@hookform/resolvers/joi";

// validation
import { contactInfoValidationSchema } from "../../../service/validations/contactInfoValidation";

// MAP
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { customisedPicker } from "./CustomisedMarkerLeaflet";
import PickLocation from "./PickLocation";

// API
import {
	getContactInfo,
	postContactInfo,
	useGetContactInfo,
} from "../../../service/profileOverviewService";

// context
import userContext from "../../../context/userInfo";
import snackContext from "../../../context/SnackProvider";

const RestaurantContact = () => {
	const { userInfo } = React.useContext(userContext);
	const snack = React.useContext(snackContext);
	const [contactInfo, setContactInfo] = React.useState({});
	// console.log(userInfo);
	// set latitude and longitude
	const [map, setMap] = React.useState(null);
	const [ltlng, setLtlng] = React.useState({
		latitude: "",
		longitude: "",
		area: "",
		city: "",
	});
	// console.log(latitude, longitude);

	// console.log(contactInfo);

	const {
		handleSubmit,
		formState: { errors },
		control,
		resetField,
	} = useForm({
		resolver: joiResolver(contactInfoValidationSchema),
	});

	const { data: contactData } = useGetContactInfo(userInfo.VendorId);

	const getVendorInformation = async () => {
		try {
			const data = contactData;
			setContactInfo(data.data);
			// store data to input
			const keylist = Object.keys(data.data);
			keylist.map((key) => {
				if (data.data[key]) {
					resetField(key, { defaultValue: data.data[key] });
				}
			});
			// ----
			setLtlng({
				latitude: data ? data.data.latitude : 0.0,
				longitude: data ? data.data.longitude : 0.0,
				area: data?.data?.area,
				city: data?.data?.city,
			});
		} catch (ex) {
			console.log(ex);
		}
	};

	React.useEffect(() => {
		// console.log(contactData);
		if (!contactData) return;
		getVendorInformation();
	}, [contactData]);
	/*
  {
    resolver: joiResolver(contactInfoValidationSchema),
  }
  */

	const contactSubmit = async (data) => {
		const submitData = {
			...data,
			latitude: document.querySelector("#latitudeInput").value,
			longitude: document.querySelector("#longitudeInput").value,
			area: document.querySelector("#area").value,
			city: document.querySelector("#city").value,
			UserName: userInfo.VendorId,
		};
		try {
			const res = await postContactInfo(submitData, userInfo.VendorId);
			if (res.status === 200) {
				snack.setSaverity("success");
				snack.setMessage("your data is Uploaded Successfully!!");
				snack.handleClick();
				return true;
			} else {
				snack.setSaverity("error");
				if (typeof res.response.data === "object")
					snack.setMessage(
						res.response.data[Object.keys(res.response.data)[0]][0]
					);
				else snack.setMessage(res.response.data);
				snack.handleClick();
				return false;
			}
		} catch (ex) {
			snack.setSaverity("error");
			try {
				snack.setMessage(ex.response.data[Object.keys(ex.response.data)[0]][0]);
			} catch {
				snack.setMessage("Something Went Wrong!!");
			}
			snack.handleClick();
			return false;
		}
	};

	const throttleContact = React.useCallback(
		throttle(1000, contactSubmit, { noLeading: true }),
		[]
	);

	React.useEffect(() => {
		if (
			map &&
			ltlng.latitude !== "string" &&
			ltlng.latitude !== null &&
			ltlng.latitude !== ""
		) {
			map.flyTo([ltlng.latitude, ltlng.longitude], 16);
		}
	}, [ltlng]);

	// console.log(errors);

	return (
		<Box
			sx={{
				width: "95vw",
				maxWidth: { xs: "95vw", md: "710px" },
			}}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"flex-start"}
			justifyContent={"flex-start"}
			rowGap={1}
			mb={3}
			component="form"
			onSubmit={handleSubmit(throttleContact)}
		>
			<Title text={"CONTACT"} />
			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					mt: 2,
					display: "flex",
					alignItems: "center",
				}}
			>
				<MdCall
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Contact Number
				</Typography>
			</Stack>
			<CustomisedInput1
				fullWidth
				placeholder="Enter your contact number"
				readOnly
				value={contactInfo.phone}
			/>
			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					mt: 2,
					display: "flex",
					alignItems: "center",
				}}
			>
				<FaLocationArrow
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Location
				</Typography>
			</Stack>
			<Controller
				control={control}
				name="address"
				render={({ field: { onChange, value, ref } }) => (
					<CustomisedInput1
						fullWidth
						onChange={onChange}
						value={value}
						inputRef={ref}
					/>
				)}
			/>
			{errors.address && (
				<Alert
					severity="warning"
					sx={{ py: 0 }}
				>
					{errors.address.message}
				</Alert>
			)}
			{/* <Stack direction={"row"} width={"100%"} columnGap={1}>
        <CustomisedInput1 placeholder="City" sx={{ flex: 0.7 }} />
        <CustomisedInput1 placeholder="Zip Code" sx={{ flex: 0.3 }} />
      </Stack> */}
			<Box
				sx={{
					boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
					borderRadius: " 5px",
					width: "100%",
					paddingLeft: 2,
					paddingRight: 2,
					paddingBottom: 1,
					paddingTop: 2,
				}}
			>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<span>
						<Typography
							variant="tabText"
							color="initial"
						>
							Map Location
						</Typography>{" "}
						<Typography variant="caption">
							({ltlng.area}, {ltlng.city})
						</Typography>
					</span>
					{/* Pick location dialog box */}
					<PickLocation
						MapProps={{
							center: [23.8103, 90.4125],
						}}
						onChange={(LatLng) => {
							setLtlng({
								latitude: LatLng.lat,
								longitude: LatLng.lng,
								area: LatLng.area,
								city: LatLng.city,
							});
						}}
					/>
					{/* --- */}
				</Stack>
				<Box
					sx={{
						height: "300px",
						width: "100%",
						bgcolor: "#ddd",
						my: 2,
						position: "relative",
						"& > *": {
							height: "100%",
						},
					}}
				>
					<MapContainer
						center={
							ltlng.latitude !== "string" &&
							ltlng.latitude !== null &&
							ltlng.latitude !== ""
								? [ltlng.latitude, ltlng.longitude]
								: [23.7562, 90.3681]
						}
						zoom={16}
						ref={setMap}
						// scrollWheelZoom={false}
					>
						<TileLayer
							attribution=""
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{ltlng.latitude !== "string" &&
						ltlng.latitude !== null &&
						ltlng.latitude !== "" ? (
							<Marker
								position={
									ltlng.latitude !== "string" &&
									ltlng.latitude !== null &&
									ltlng.latitude !== ""
										? [ltlng.latitude, ltlng.longitude]
										: [23.7562, 90.3681]
								}
								icon={customisedPicker(30)}
							>
								<Popup>
									A pretty CSS3 popup. <br /> Easily customizable.
								</Popup>
							</Marker>
						) : (
							<></>
						)}
					</MapContainer>
				</Box>
				<input
					// {...register("latitude")}
					value={ltlng.latitude}
					id={"latitudeInput"}
					style={{ display: "none" }}
				/>
				<input
					// {...register("longitude")}
					value={ltlng.longitude}
					id={"longitudeInput"}
					style={{ display: "none" }}
				/>
				<input
					// {...register("latitude")}
					value={ltlng.area}
					id={"area"}
					style={{ display: "none" }}
				/>
				<input
					// {...register("longitude")}
					value={ltlng.city}
					id={"city"}
					style={{ display: "none" }}
				/>
				{/* <iframe icon={customisedPicker}
          width={"100%"}
          height="300px"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.15521887716127!2d90.42605143176662!3d23.80140001946994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7f8162ec0ff%3A0x241017f31660f6e!2sSafa%20coffee%20fast%20food%20and%20mini%20resturent!5e0!3m2!1sbn!2sbd!4v1649576465207!5m2!1sbn!2sbd"
          style={{
            border: 0,
          }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
			</Box>

			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					mt: 2,
					display: "flex",
					alignItems: "center",
				}}
			>
				<BsGlobe
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Web link URL
				</Typography>
			</Stack>
			<Controller
				control={control}
				name="websiteURL"
				render={({ field: { onChange, value, ref } }) => (
					<CustomisedInput1
						fullWidth
						placeholder="Enter your web link"
						onChange={onChange}
						value={value}
						inputRef={ref}
					/>
				)}
			/>
			{errors.websiteURL && (
				<Alert
					severity="warning"
					sx={{ py: 0 }}
				>
					{errors.websiteURL.message}
				</Alert>
			)}
			{/* <Stack
        direction={"row"}
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      ></Stack> */}
			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<FaFacebook
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Facebook link URL
				</Typography>
			</Stack>
			<Controller
				control={control}
				name="facebookURL"
				render={({ field: { onChange, value, ref } }) => (
					<CustomisedInput1
						fullWidth
						placeholder="Enter your facebook link"
						onChange={onChange}
						value={value}
						inputRef={ref}
					/>
				)}
			/>
			{errors.facebookURL && (
				<Alert
					severity="warning"
					sx={{ py: 0 }}
				>
					{errors.facebookURL.message}
				</Alert>
			)}
			{/* <Stack
        direction={"row"}
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      ></Stack> */}
			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<FaInstagram
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Instragram link URL
				</Typography>
			</Stack>
			<Controller
				control={control}
				name="instagramURL"
				render={({ field: { onChange, value, ref } }) => (
					<CustomisedInput1
						fullWidth
						placeholder="Enter your instragram link"
						onChange={onChange}
						value={value}
						inputRef={ref}
					/>
				)}
			/>
			{errors.instagramURL && (
				<Alert
					severity="warning"
					sx={{ py: 0 }}
				>
					{errors.instagramURL.message}
				</Alert>
			)}
			{/* <Stack
        direction={"row"}
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      ></Stack> */}
			<Stack
				direction={"row"}
				spacing={1}
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<FaTwitter
					style={{
						fontSize: "1.2rem",
						color: "#401B60",
					}}
				/>
				<Typography
					variant="tabText"
					color="primary"
				>
					Twitter link URL
				</Typography>
			</Stack>
			<Controller
				control={control}
				name="twitterURL"
				render={({ field: { onChange, value, ref } }) => (
					<CustomisedInput1
						fullWidth
						placeholder="Enter your twitter link"
						onChange={onChange}
						value={value}
						inputRef={ref}
					/>
				)}
			/>
			{errors.twitterURL && (
				<Alert
					severity="warning"
					sx={{ py: 0 }}
				>
					{errors.twitterURL.message}
				</Alert>
			)}
			{/* <Stack
        direction={"row"}
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      ></Stack> */}
			<Button
				variant="contained"
				color="primary"
				fullWidth
				type="submit"
			>
				Upload
			</Button>
		</Box>
	);
};

export default RestaurantContact;
