import React from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdClose } from "react-icons/md";
import { FaSearchLocation } from "react-icons/fa";
import { MapContainer, TileLayer } from "react-leaflet";
import axios from "axios";
import PickIcon from "../../../assets/pick.svg";
import { Box } from "@mui/material";

// Map Picker
const PickLocation = ({
  MapProps,
  IconButtonProps,
  onChange,
  DialogProps,
  PaperProps,
  ...others
}) => {
  const [value, setValue] = React.useState();
  const utheme = useTheme();
  const fullScreen = useMediaQuery(utheme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  // MAP VARIABLES
  const [map, setMap] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [markerY, setMarkerY] = React.useState("-100%");

  // Display Map From Here

  const displayMap = React.useMemo(
    () => (
      <MapContainer
        center={MapProps.center}
        zoom={10}
        style={{ position: "relative", width: "100%", height: "100%" }}
        // scrollWheelZoom={false}
        ref={setMap}
      >
        <TileLayer
          attribution=""
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    ),
    []
  );

  const onMove = React.useCallback(() => {
    // console.log();
    //console.log("hello");
    setMarkerY("calc(-100% - 10px)");
  }, [map]); //setMarkerY("-100%")

  React.useEffect(() => {
    if (map) {
      map.on("move", onMove);
      return () => {
        map.off("move", onMove);
      };
    }
  }, [map, onMove]);

  React.useEffect(() => {
    if (map) {
      map.on("movestart", () => {
        setMarkerY("calc(-100% - 10px)");
      });
      map.on("moveend", () => {
        setMarkerY("-100%");
      });
    }
  }, [map]);

  return (
    <>
      <IconButton
        size={"small"}
        color={"primary"}
        onClick={handleOpen}
        {...IconButtonProps}
        {...others}
      >
        <FaSearchLocation />
      </IconButton>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { sm: "90vw", md: "800px" },
            minHeight: "80vh",
          },
          ...PaperProps,
        }}
        fullScreen={fullScreen}
        onClose={handleOpen}
        {...DialogProps}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Select Location</Typography>
          <span>
            <Button
              size={"small"}
              variant={"contained"}
              sx={{ mr: 1 }}
              onClick={() => {
                if (typeof onChange === "function") {
                  onChange({
                    ...map.getCenter(),
                    // add city & area here
                    ...value,
                  });
                }
                handleOpen();
              }}
            >
              Save
            </Button>
            <IconButton size={"small"} onClick={handleOpen}>
              <MdClose />
            </IconButton>
          </span>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0, position: "relative" }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            value={value}
            sx={{
              width: "100%",
              mx: "auto",
              "& fieldset": {
                border: "none",
                outline: "none",
              },
            }}
            getOptionLabel={(option) => option.address}
            onChange={(e, newValue) => {
              setValue(newValue);
              map.flyTo([newValue.latitude, newValue.longitude], 16);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Search Location"
                placeholder="Search City, Union, Place..."
                onChange={async (e) => {
                  // setValue(e.target.value);
                  if (e.target.value)
                    try {
                      const response = await axios.get(
                        `https://barikoi.xyz/v1/api/search/autocomplete/MzM4MDozT0cxNEFOREtU/place?q=${e.target.value}`
                      );
                      if (response.status === 200) {
                        setOptions(response.data.places);
                      }
                    } catch (error) {
                      console.error(error);
                    }
                }}
              />
            )}
          />
          <Box
            sx={{
              position: "relative",
              "& .leaflet-control-attribution": {
                display: "none",
              },
              "& .leaflet-container": {
                minHeight: {
                  xs: "84vh",
                  sm: "70vh",
                },
              },
            }}
          >
            {displayMap}
            {map ? (
              <img
                src={PickIcon}
                style={{
                  position: "absolute",
                  height: "40px",
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, ${markerY})`,
                  zIndex: 900,
                  fontSize: "2rem",
                }}
              />
            ) : null}
            {map ? (
              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  background: "#7173be",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -100%)`,
                  zIndex: 899,
                }}
              />
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PickLocation;
