import { styled } from "@mui/material/styles";
import { Tab } from "@mui/material";

const CTab1 = styled(Tab)(({ theme }) => ({
	textTransform: "none",
	fontSize: { xs: "0.7rem", md: "1rem" },
	// minWidth: "140px",
	fontWeight: "bold",
	borderRadius: "50px",
	"&:hover": {
		color: theme.palette.white.contrastText,
		opacity: 1,
	},
	"&.Mui-selected": {
		color: theme.palette.white.main,
		background: theme.palette.white.contrastText,
	},
	"&.Mui-focusVisible": {
		backgroundColor: "#d1eaff",
	},
}));

export default CTab1;
