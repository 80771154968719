import React from "react";
import { TableRow, TableCell, Stack, IconButton } from "@mui/material";

// Icons
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";

// components
import EditUserInfoDrawer from "./EditUserInfoDrawer";

// receive some props from CreateUserForACL.js file
const EditUserInfoDrawerTableRow = ({ info }) => {
  // openEditDrawer
  const [openEditDrawer, setOpenEditDrawer] = React.useState(false);

  return (
    <>
      <TableRow
        key={info.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center" component="th" scope="row">
          {info.Name}
        </TableCell>
        <TableCell align="center">{info.Title}</TableCell>
        <TableCell align="center">{info.Email}</TableCell>
        <TableCell align="center">{info.Phone}</TableCell>
        <TableCell align="center">{info.Access}</TableCell>

        <TableCell>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
              color: "#C4C4C4",
            }}
          >
            <IconButton onClick={() => setOpenEditDrawer(true)}>
              <BiEdit style={{ fontSize: "18px" }} />
            </IconButton>
            <IconButton>
              <RiDeleteBin5Line style={{ fontSize: "18px" }} />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <EditUserInfoDrawer
        open={openEditDrawer}
        setOpen={setOpenEditDrawer}
        name={info.Name}
        email={info.Email}
        phone={info.Phone}
        access={info.Access}
        id={info.id}
      />
    </>
  );
};

export default EditUserInfoDrawerTableRow;

/**
 *
 * this just show some information on CreateUserForACL.js file.
 * VA can add, edit, delete user using this drawer.
 *
 * created by noman :)
 */
