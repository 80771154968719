import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useState } from "react";

//Components
import UserProfileCration from "./UserProfileCration";
import EditUserInfoDrawerTableRow from "./EditUserInfoDrawerTableRow";

const CreateUserForACL = () => {
  const [open, setOpen] = useState(false);
  const [info] = useState([
    {
      id: 1,
      Name: "Mr .XYZ",
      Title: "General Manager",
      Email: " General@example.com",
      Phone: "016********6",
      Access: "Restaurant",
    },
    {
      id: 2,
      Name: "Mr .XYZ",
      Title: "Other",
      Email: " other@example.com",
      Phone: "016********6",
      Access: "All access",
    },
    {
      id: 3,
      Name: "Mr .RRTR",
      Title: "Manager",
      Email: " orrtr@example.com",
      Phone: "017********6",
      Access: "All access",
    },
    {
      id: 4,
      Name: "Mr. YYY",
      Title: "Asistant Manager",
      Email: "YYY@example.com",
      Phone: "015********6",
      Access: "All access",
    },
    {
      id: 5,
      Name: "Mr. KKKKK",
      Title: "Waiter",
      Email: "kkkkkk@example.com",
      Phone: "019********6",
      Access: "All access",
    },
    {
      id: 6,
      Name: "Mr.LPLLPPP",
      Title: "Manager",
      Email: "orrasdfasdf@example.com",
      Phone: "017********6",
      Access: "All access",
    },
  ]);
  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "90vw",
          margin: "0 auto",
          marginTop: "20px",
        }}
        spacing={1}
        mb={1}
      >
        <Button
          variant="contained"
          color="primary"
          open={open}
          onClick={() => setOpen(true)}
        >
          Add New User
        </Button>
      </Stack>
      <Box sx={{ width: "90vw", margin: "0 auto", mt: 2 }}>
        <TableContainer component={Paper}>
          <Table
            sx={{
              width: "100%",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E3E3E3" }}>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Title </TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Access</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.map((info) => (
                <EditUserInfoDrawerTableRow info={info} key={info.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <UserProfileCration open={open} setOpen={setOpen} />
    </>
  );
};

export default CreateUserForACL;

/**
 * create by md.masum
 * updated by noman :)
 */
