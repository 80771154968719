import { Typography, Box } from "@mui/material";
import React from "react";
import userContext from "../../../context/userInfo";
import ImageUploader from "../../../components/ImageUploader";

// Components
import Title from "../../../components/Title";
import {
	useDeletePhotos,
	useGetGallery,
	usePhotoUpload,
} from "../../../service/profileGallery";
import snackContext from "../../../context/SnackProvider";
import { responseHandler } from "../../../utilities/responseHandler";

const PhotosTabForRestaurantProfile = () => {
	// context
	const { userInfo } = React.useContext(userContext);
	// userInfo.VendorId
	const snack = React.useContext(snackContext);

	const [photos, setPhotos] = React.useState({
		Food: [],
		Interior: [],
		Exterior: [],
	});

	const { data: photoData } = useGetGallery(userInfo.VendorId);

	const { mutateAsync: uploadPhoto } = usePhotoUpload();
	const { mutateAsync: deletePhoto } = useDeletePhotos();

	// run in start
	React.useEffect(() => {
		if (photoData) {
			var temp = {
				Food: [],
				Interior: [],
				Exterior: [],
			};
			photoData.data.map((per) => {
				temp[per.imageType].push(per);
			});
			setPhotos(temp);
		}
	}, [photoData]);

	console.log(photos);

	const upPhoto = async (file, type) => {
		const res = await responseHandler(
			() => uploadPhoto({ id: userInfo.VendorId, file: file, type: type }),
			201
		);
		// console.log(res);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage(type + " Photo added successfully !");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	const delPhoto = async (filename) => {
		const res = await responseHandler(() =>
			deletePhoto({ id: userInfo.VendorId, filename: filename })
		);
		// console.log(res);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Photo deleted successfully !");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	return (
		<Box style={{ height: "fit-content", width: "100%" }}>
			<Title text="PHOTOS" />

			<section>
				<Typography
					variant="header1"
					sx={{ marginLeft: "12px" }}
				>
					Food Photos
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						rowGap: 2,
						columnGap: 2,
						mt: 3,
					}}
				>
					{photos.Food?.map((photo) => (
						<ImageUploader
							key={photo.id}
							onChange={async (file) => upPhoto(file, "Food")}
							onDelete={async (file) => delPhoto(file.name)}
							defaultValue={{
								preview: `https://api.reserveitbd.com/api/restaurant-profile/${userInfo.VendorId}/file/${photo.fileName}`,
								name: photo.fileName,
								details: photo,
							}}
						/>
					))}
					{photos.Food?.length < 10 && (
						<ImageUploader onChange={async (file) => upPhoto(file, "Food")} />
					)}
				</Box>
			</section>

			<section style={{ margin: "20px 0px 10px 0px" }}>
				<Typography
					variant="header1"
					sx={{ marginLeft: "12px" }}
				>
					Interior Photos
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						rowGap: 2,
						columnGap: 2,
						mt: 3,
					}}
				>
					{photos.Interior?.map((photo) => (
						<ImageUploader
							key={photo.id}
							onChange={async (file) => upPhoto(file, "Interior")}
							onDelete={async (file) => delPhoto(file.name)}
							defaultValue={{
								preview: `https://api.reserveitbd.com/api/restaurant-profile/${userInfo.VendorId}/file/${photo.fileName}`,
								name: photo.fileName,
								details: photo,
							}}
						/>
					))}
					{photos.Interior.length < 10 && (
						<ImageUploader
							onChange={async (file) => upPhoto(file, "Interior")}
						/>
					)}
				</Box>
			</section>
			<section style={{ margin: "20px 0px 10px 0px" }}>
				<Typography
					variant="header1"
					sx={{ marginLeft: "12px" }}
				>
					Exterior Photos
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						rowGap: 2,
						columnGap: 2,
						mt: 3,
					}}
				>
					{photos.Exterior?.map((photo) => (
						<ImageUploader
							key={photo.id}
							onChange={async (file) => upPhoto(file, "Exterior")}
							onDelete={async (file) => delPhoto(file.name)}
							defaultValue={{
								preview: `https://api.reserveitbd.com/api/restaurant-profile/${userInfo.VendorId}/file/${photo.fileName}`,
								name: photo.fileName,
								details: photo,
							}}
						/>
					))}
					{photos.Exterior.length < 10 && (
						<ImageUploader
							onChange={async (file) => upPhoto(file, "Exterior")}
						/>
					)}
				</Box>
			</section>
		</Box>
	);
};

export default PhotosTabForRestaurantProfile;

/**
 *
 *
 * In this component I plan to render all photo upload frame for this.
 *
 *
 */
