import React from "react";
import { Box, Stack } from "@mui/material";
import { Link, useLocation, Routes, Route } from "react-router-dom";

// Components
import CTab1 from "../../components/CTab1";
import CustomisedTabs1 from "../../components/CustomisedTabs1";
import RestaurantContact from "./restaurantContact";
import PhotosTabForRestaurantProfile from "./photos";
import RestaurantProfileMenu from "./menu";
import RestaurantProfileOverview from "./overview";
import RestaurantProfileOpeningHours from "./openingHours";

const labels = [
	{ label: "Overview", value: "/restaurant-profile" },
	{ label: "Contact", value: "/restaurant-profile/contact" },
	{ label: "Menu", value: "/restaurant-profile/menu" },
	{ label: "Photos", value: "/restaurant-profile/image" },
	{ label: "Opening Hours", value: "/restaurant-profile/opening-hours" },
];

const RestaurantProfile = () => {
	const location = useLocation().pathname;

	return (
		<Stack
			direction={"column"}
			alignItems={"center"}
			justifyContent={"flex-start"}
			minHeight={"90vh"}
			minWidth={"100vw"}
		>
			<CustomisedTabs1
				value={location}
				// onChange={handleInfoTabChange}
				sx={{
					width: "100%",
					maxWidth: { xs: "95vw", md: "800px" },
					my: 3,
				}}
				variant="scrollable"
				scrollButtons="auto"
			>
				{labels.map((level, id) => (
					<CTab1
						key={id}
						label={level.label}
						value={level.value}
						component={Link}
						to={level.value}
					/>
				))}
			</CustomisedTabs1>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				width={"95vw"}
				maxWidth={{ xs: "95vw", md: "810px" }}
			>
				<Routes path={"restaurant-profile"}>
					<Route
						path={"contact/*"}
						element={<RestaurantContact />}
					/>
					<Route
						path={"menu/*"}
						element={<RestaurantProfileMenu />}
					/>
					<Route
						path={"image/*"}
						element={<PhotosTabForRestaurantProfile />}
					/>
					<Route
						path={"opening-hours/*"}
						element={<RestaurantProfileOpeningHours />}
					/>
					<Route
						index
						element={<RestaurantProfileOverview />}
					/>
				</Routes>
			</Box>
		</Stack>
	);
};

export default RestaurantProfile;
