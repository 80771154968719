import React from "react";
import { Outlet } from "react-router-dom";
import authContext from "../../context/authContext";

// component
import Header from "../../components/header";
import Login from "../login";
import { Box } from "@mui/material";
// hook

// export const useAuth = () => {
//   const { userInfo } = useContext(userContext);
//   const user = { isLoggedin: true };

//   if (userInfo) {
//     user.isLoggedin = true;
//   } else {
//     return (user.isLoggedin = false);
//   }

//   return user.isLoggedin;
// };

const ProtectedRoute = () => {
	const authCntxt = React.useContext(authContext);
	// console.log("is Authenticated: ", isAuth);
	return authCntxt.isLoggedIn ? (
		<>
			<Header />
			<Box
				// Maintain the Content Box
				minHeight={{ xs: "calc(100vh - 64px)", sm: "calc(100vh - 72px)" }}
				sx={{
					overflowX: "hidden",
					overflowY: "auto",
					mt: { xs: 8, sm: 9 },
				}}
			>
				<Outlet />
			</Box>
		</>
	) : (
		<Login />
	);
};

export default ProtectedRoute;
