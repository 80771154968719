import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// import some props comming from CreateACL.js file.

const ShowAccessListItem = ({ data }) => {
  return (
    <>
      <Paper
        sx={{
          p: 1,
          boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography align="center">{data}</Typography>
      </Paper>
    </>
  );
};

export default ShowAccessListItem;
/**
 *
 * created by noman :)
 */
