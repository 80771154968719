import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Box } from "@mui/material";

// components
import CBox from "../../../components/CBox";
import Title from "../../../components/Title";
import svg1 from "../../../assets/Reserve it-01 1.svg";

const Help = () => {
  return (
    <>
      <Box sx={{ width: { lg: "50vw", xms: "90vw", xs: "90vw" }, mb: 1 }}>
        <Title text={"Help"} />
        <CBox style={{ marginTop: "1rem" }}>
          <Stack
            direction={"column"}
            sx={{ justifyContent: "center", alignItems: "center" }}
            spacing={1}
          >
            <Typography variant="tabText" color="initial" textAlign={"center"}>
              {`Need assistance? We’re happy to help. Please visit our support site or started.`}
            </Typography>
            <Stack direction={"column"} alignItems={"center"}>
              <Typography variant="normal" color="#858585">
                Version 1.0.1 (121)
              </Typography>
              <Typography variant="normal" color="#858585">
                Restaurant ID #1231
              </Typography>
            </Stack>
          </Stack>
        </CBox>
        <CBox style={{ marginTop: "1rem", width: { lg: "70vw", sm: "90vw" } }}>
          <Stack direction={"row"} spacing={4}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                "& img": {
                  width: { lg: "80vw", xms: "90vw" },
                  maxWidth: { xs: "60px", sm: "120px" },
                },
              }}
            >
              <img src={svg1} alt="" srcSet="" />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography
                variant="tabText"
                color="initial"
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Visit our support site
              </Typography>
              <Typography variant="legend" color="#858585">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam
                iste saepe atque eius aspernatur magni eos iure optio sunt,
                illum sit aliquam
              </Typography>
            </Stack>
          </Stack>
        </CBox>
        <CBox style={{ marginTop: "1rem", width: { lg: "70vw", sm: "90vw" } }}>
          <Stack direction={"row"} spacing={4}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                "& img": {
                  width: { lg: "80vw", xms: "90vw" },
                  maxWidth: { xs: "60px", sm: "120px" },
                },
              }}
            >
              <img src={svg1} alt="" srcSet="" />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography
                variant="tabText"
                color="initial"
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Access Reserveit Web
              </Typography>
              <Typography variant="legend" color="#858585">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam
                iste saepe atque eius aspernatur magni eos iure optio sunt,
                illum sit aliquam ?
              </Typography>
            </Stack>
          </Stack>
        </CBox>
      </Box>
    </>
  );
};

export default Help;
/**
 *
 *
 * Create - masum
 *
 */
