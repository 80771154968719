import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";
import { CgCloseR } from "react-icons/cg";
import { BiSelectMultiple } from "react-icons/bi";
import CustomisedInput1 from "../../components/CustomisedInput1";

import { arrayRemove } from "../../utilities/array";

const RoleDrawer = ({ open, setOpen }) => {
  const [list] = React.useState([
    {
      title: "Super Admin - Full Control",
      select: false,
    },
    {
      title: "Super Admin - Dashboard",
      select: true,
    },
    {
      title: "Super Admin - Reservation",
      select: false,
    },
    {
      title: "Super Admin - Resevation",
      select: true,
    },
    {
      title: "Super Admin - Restaurant",
      select: false,
    },
    {
      title: "Super Admin - User",
      select: false,
    },
  ]);

  const [selectedIDs, setSelectedIDs] = React.useState([]);
  const [deselectedIDs, setDeselectedIDs] = React.useState([]);

  return (
    <>
      {" "}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            // drawer width
            width: { xs: "90vw", xms: 300, sm: 400, lg: 700 },
          },
        }}
        // drawer animation
        transitionDuration={100}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            width: "100%",
            py: 1,
          }}
        >
          <Stack direction={"column"} px={2}>
            <Typography variant="header1" color="initial">
              Add New Role
            </Typography>
            <Typography variant="cardLocation1" color="initial">
              Add your information from here
            </Typography>
          </Stack>

          <Stack>
            <IconButton
              // Close Drawer Icon
              onClick={() => setOpen(false)}
              sx={{
                mr: 1,
                my: { xs: 1, sm: 1.5 },
                backgroundColor: "#fff",
                color: "#F26B8C",
              }}
              size={"small"}
            >
              <MdClose />
            </IconButton>
          </Stack>
        </Box>

        <Box
          sx={{
            width: "95%",
            margin: "0 auto",
            minHeight: "calc(100vh - 250px)",
          }}
        >
          <Stack direction={"column"} mt={1} spacing={1}>
            <Typography variant="tabText" color="initial">
              Role Name
            </Typography>
            <CustomisedInput1
              placeholder="Role Name"
              sx={{ borderColor: "#FBB03B" }}
            />
          </Stack>
          <Grid
            container
            mt={1}
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={12} lg={6}>
              <Paper
                sx={{
                  boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
                  height: "98%",
                  m: 0.5,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);", p: 0.5 }}
                >
                  <Button
                    startIcon={
                      <BiSelectMultiple
                        style={{
                          // fontSize: '1.4rem',
                          color: "#444",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    Select
                  </Button>
                  <Button
                    startIcon={
                      <CgCloseR
                        style={{
                          fontSize: "1rem",
                          color: "#444",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    Unselect
                  </Button>
                </Stack>
                <ToggleButtonGroup
                  orientation="vertical"
                  value={deselectedIDs}
                  sx={{
                    width: "100%",
                    minHeight: "200px",
                    height: "100%",
                    overflowY: "auto",
                    p: 1,
                  }}
                  onChange={(e) => {
                    // console.log(deselectedIDs);
                    if (!deselectedIDs.includes(parseInt(e.target.value)))
                      setDeselectedIDs([
                        ...deselectedIDs,
                        parseInt(e.target.value),
                      ]);
                    else
                      setDeselectedIDs(
                        arrayRemove(deselectedIDs, parseInt(e.target.value))
                      );
                  }}
                >
                  {list?.map((item, index) => {
                    if (!item.select)
                      return (
                        <ToggleButton
                          value={index}
                          key={index}
                          style={{
                            p: 1,
                            border: "none",
                            boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
                          }}
                          fullWidth
                        >
                          {item.title}
                        </ToggleButton>
                      );
                  })}
                </ToggleButtonGroup>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper
                sx={{
                  boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
                  height: "98%",
                  m: 0.5,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);", p: 0.5 }}
                >
                  <Button
                    startIcon={
                      <CgCloseR
                        style={{
                          fontSize: "1rem",
                          color: "#444",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "#444",
                    }}
                  >
                    Unselect
                  </Button>
                </Stack>
                <ToggleButtonGroup
                  orientation="vertical"
                  value={selectedIDs}
                  sx={{
                    width: "100%",
                    minHeight: "200px",
                    height: "100%",
                    overflowY: "auto",
                    p: 1,
                  }}
                  onChange={(e) => {
                    // console.log(deselectedIDs);
                    if (!selectedIDs.includes(parseInt(e.target.value)))
                      setSelectedIDs([
                        ...selectedIDs,
                        parseInt(e.target.value),
                      ]);
                    else
                      setSelectedIDs(
                        arrayRemove(selectedIDs, parseInt(e.target.value))
                      );
                  }}
                >
                  {list?.map((item, index) => {
                    if (item.select)
                      return (
                        <ToggleButton
                          value={index}
                          key={index}
                          style={{
                            p: 1,
                            border: "none",
                            boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
                          }}
                          fullWidth
                        >
                          {item.title}
                        </ToggleButton>
                      );
                  })}
                </ToggleButtonGroup>
              </Paper>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                minWidth: { xs: "100%", md: "200px" },
                mb: 2,
              }}
              onClick={() => setOpen(!open)}
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default RoleDrawer;
