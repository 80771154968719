import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Typography,
	styled,
	Fab,
	Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import { useForm } from "react-hook-form";

//Icons
import { MdClose } from "react-icons/md";
import { FiEdit, FiEdit2 } from "react-icons/fi";

// context
import userContext from "../../../context/userInfo";
import snackContext from "../../../context/SnackProvider";

// component
import ImageUploader from "../../../components/ImageUploader";
import CustomisedInput1 from "../../../components/CustomisedInput1";
import RitSelect from "../../../components/RitSelect";
import Title from "../../../components/Title";

// handler
import { responseHandler } from "../../../utilities/responseHandler";

// API section
import {
	useCreateAndUpdateFoodItem,
	useGetMainCategories,
	useGetSubCategory,
	useUpdateSubcategory,
	useGetAllFood,
	useDelSubcategory,
	useDeleteFoodItem,
} from "../../../service/useQuery/useMenu";
import AreYouSure from "../../../components/AreYouSure";
import { backendURL } from "../../../service/httpService";

// component design

const CustomiseInput = styled(CustomisedInput1)(({ theme }) => ({
	height: "38px",
	boxShadow: "none",
	border: `1px solid ${theme.palette.primary.main}`,
}));

const RestaurantProfileMenu = () => {
	//For responsive view
	const theme = useTheme();
	const smAndDown = useMediaQuery(theme.breakpoints.down("sm"));

	// use Context
	const { userInfo } = useContext(userContext);
	const snack = useContext(snackContext);
	// console.log(userInfo);
	// State for category and subCategory select
	const [menuCategory, setMenuCategory] = React.useState("unselectable");
	const [menuSubCategory, setMenuSubCategory] = React.useState("unselectable");
	const [restaurantMenuItem, setRestaurantMenuItem] = React.useState([]);
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [subCategoryListToShow, setSubCategoryListToShow] = React.useState([]);

	// for toggle drawer
	const toggleDrawer = () => {
		setOpenDrawer(!openDrawer);
		cancelAddMenu();
		reset();
	};

	// main category change
	const handleMenuCategoryChange = (event) => {
		setMenuCategory(event.target.value);
		setValue("CuisineSubCategory", null);
	};

	// sub category change
	const handleSubCategoryChange = (e) => {
		setMenuSubCategory(e.target.value);
	};

	// Cancel add menu
	const cancelAddMenu = () => {
		setMenuCategory("unselectable");
		setMenuSubCategory("unselectable");
	};

	// get main-category
	const { data, isLoading, isError } = useGetMainCategories();
	// console.log(isError);

	// get sub-catergory
	const { data: subCategoryData, isError: subCategoryError } =
		useGetSubCategory(userInfo.VendorId);

	// use create & update sub-category
	const { mutateAsync: updateSubCategory } = useUpdateSubcategory();

	// listener
	const updateSubCategoryHandle = (data) => {
		console.log(data);
		if (data.categoryID === "unselectable") {
			snack.setSaverity("error");
			snack.setMessage("Select Category");
			snack.handleClick();
		} else {
			updateSC(data);
		}
	};

	// update subcategory
	const updateSC = async (data) => {
		const res = await responseHandler(
			() =>
				updateSubCategory({
					id: userInfo.VendorId,
					data: data,
				}),
			[201, 204]
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Sub Category List Updated Successfully!!");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	// del sub-category useDelSubcategory
	const { mutateAsync: delSubCategory } = useDelSubcategory();

	const delSC = async (data) => {
		const res = await responseHandler(() =>
			delSubCategory({
				id: userInfo.VendorId,
				categoryId: data.value,
			})
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Sub Category List Deleted Successfully!!");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	React.useEffect(() => {
		if (subCategoryData) {
			setMenuSubCategory("unselectable");
			var tempList = subCategoryData.data
				.filter((d) => d.cuisineCategory.id === menuCategory)
				.map((d) => {
					return {
						title: d.name,
						value: d.id,
						details: d,
					};
				});
			setSubCategoryListToShow(tempList);
		}
	}, [menuCategory, subCategoryData]);

	// upload menu image
	const createFoodImageUpload = (file) => {
		// const tempFile = [file];
		setValue("Image", file);
		return true;
	};
	// delete fontLanguageOverride
	const deleteFood = () => {
		setValue("Image", undefined);
		return true;
	};

	// create food item
	const { handleSubmit, reset, register, setValue } = useForm();
	const { mutateAsync: createFood } = useCreateAndUpdateFoodItem();
	const createFoodItem = async (data) => {
		const res = await responseHandler(
			() =>
				createFood({
					id: userInfo.VendorId,
					data: data,
				}),
			201
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Food Item Uploaded Successfully!!");
			snack.handleClick();
			reset();
			toggleDrawer();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	// Get all food

	const { data: allFood, isLoading: foodLoading } = useGetAllFood(
		userInfo.VendorId
	);

	React.useEffect(() => {
		// console.log(allFood, menuCategory, menuSubCategory);
		if (allFood) {
			var filterList1 = allFood;
			if (menuCategory !== "unselectable") {
				console.log("hello");
				filterList1 = filterList1.filter(
					(val) => val["cuisineCatgory"]["id"] === menuCategory
				);
			}
			if (menuSubCategory !== "unselectable") {
				console.log("hello");
				filterList1 = filterList1.filter(
					(val) => val["cuisineSubCategory"]["id"] === menuSubCategory
				);
			}
			// console.log(filterList1);
			setRestaurantMenuItem(filterList1);
		}
	}, [allFood, menuCategory, menuSubCategory]);

	return (
		<>
			<Title text="MENU" />
			<Paper
				sx={{
					flexGrow: 1,
					boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
					borderRadius: "5px",
					mt: 3,
					width: "100%",
				}}
			>
				<Stack
					direction={smAndDown ? "column" : "row"}
					justifyContent={smAndDown ? "center" : "space-between"}
					alignItems={smAndDown ? "center" : "flex-end"}
					spacing={smAndDown ? 2 : 1}
					sx={{
						p: 2,
						"& > *": {
							flexGrow: 1,
						},
					}}
				>
					<Stack
						spacing={1}
						flexDirection={"column"}
						alignItems="flex-start"
						justifyContent="center"
						width={"100%"}
					>
						<Typography variant="legend">Select Category</Typography>
						<Select
							value={menuCategory}
							onChange={handleMenuCategoryChange}
							size={"small"}
							sx={{ px: 1, width: "100%" }}
						>
							<MenuItem
								disabled
								value={"unselectable"}
							>
								Select Category
							</MenuItem>
							{data?.data.map((data) => (
								<MenuItem
									key={data.id}
									value={data.id}
								>
									{data.name}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack
						spacing={1}
						flexDirection={"column"}
						alignItems="flex-start"
						justifyContent="center"
						width={"100%"}
					>
						<Typography variant="legend">Select Sub Category</Typography>
						<Select
							value={menuSubCategory}
							onChange={handleSubCategoryChange}
							size={"small"}
							sx={{ px: 1, width: "100%" }}
							disabled={menuCategory === "unselectable"}
						>
							<MenuItem
								disabled
								value={"unselectable"}
							>
								Select Sub Category
							</MenuItem>
							{subCategoryListToShow?.map((data) => (
								<MenuItem
									key={data.id}
									value={data.value}
								>
									{data.title}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Button
						variant="contained"
						sx={{ minHeight: "40px", width: "100%" }}
						onClick={toggleDrawer}
					>
						Add new menu
					</Button>
				</Stack>
			</Paper>
			{/* Show restaurant menu items */}
			{/* Using this sction vendor admin can edit menu item from existing menu. */}
			<Stack>
				<Typography
					variant="tabText"
					sx={{ my: 3 }}
				>
					Menu
				</Typography>
				<Grid
					container
					justifyContent={"flex-start"}
					alignItems="center"
					spacing={2}
					width={"95vw"}
					maxWidth={{ xs: "95vw", md: "810px" }}
					mb={2}
				>
					{restaurantMenuItem.map((foodItem) => (
						<Grid
							item
							key={foodItem.id}
							xs={12}
							md={6}
							lg={6}
						>
							<EditMenuDrawer
								data={foodItem}
								categoryList={data}
								subCategoryData={subCategoryData}
							/>
						</Grid>
					))}
				</Grid>
			</Stack>
			{/* Show menu item edit drawer */}
			{/* Show add menu drawer */}
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={toggleDrawer}
				sx={{ width: "50rem" }}
				PaperProps={{ sx: { width: { xs: "90%", md: "80%", lg: "480px" } } }}
			>
				<Stack
					direction={"row"}
					justifyContent="space-between"
					alignItems="center"
					sx={{ bgcolor: "#F8F8F8", p: 2 }}
				>
					<Stack>
						<Typography variant="header1">Add menu</Typography>
						<Typography variant="cardLocation1">
							Add a menu item for your restaurant
						</Typography>
					</Stack>
					<IconButton
						// Close Drawer Icon
						onClick={toggleDrawer}
						sx={{
							mr: 1,
							my: { xs: 1, sm: 1.5 },
							backgroundColor: "#fff",
							color: "#F26B8C",
						}}
						size={"small"}
					>
						<MdClose />
					</IconButton>
				</Stack>
				{/* Form component */}
				{/* Using this form vandor admin can add a new menu item */}

				<Box
					sx={{
						"& > form": {
							px: 2,
						},
						"& > form > *": {
							mt: 2,
						},
					}}
				>
					<Stack
						direction={smAndDown ? "column" : "row"}
						justifyContent={smAndDown ? "center" : "space-between"}
						alignItems={smAndDown ? "center" : "flex-end"}
						spacing={smAndDown ? 2 : 1}
						sx={{
							p: 2,
							"& > *": {
								flexGrow: 1,
							},
						}}
					>
						<Stack
							spacing={1}
							flexDirection={"column"}
							alignItems="flex-start"
							justifyContent="center"
							width={"100%"}
						>
							<Typography variant="legend">Select Category</Typography>
							<Select
								value={menuCategory}
								onChange={handleMenuCategoryChange}
								size={"small"}
								sx={{ px: 1, width: "100%" }}
							>
								{isError ? (
									<MenuItem
										disabled
										value={"unselectable"}
									>
										No Item Available
									</MenuItem>
								) : (
									<MenuItem
										disabled
										value={"unselectable"}
									>
										Select Category
									</MenuItem>
								)}
								{data?.data.map((data) => (
									<MenuItem
										key={data.id}
										value={data.id}
									>
										{data.name}
									</MenuItem>
								))}
							</Select>
						</Stack>
						<Stack
							spacing={1}
							flexDirection={"column"}
							alignItems="flex-start"
							justifyContent="center"
							width={"100%"}
						>
							<Typography variant="legend">Select Sub Category</Typography>
							<RitSelect
								id={"sub-category-drawer"}
								menuList={subCategoryListToShow}
								// {...register("CuisineSubCategory")}
								// defaultValue={menuSubCategory}
								placeholder={"Select Sub Category"}
								onSelect={(data) => setValue("CuisineSubCategory", data.value)}
								onAdd={(data) =>
									updateSubCategoryHandle({
										name: data.title,
										categoryID: menuCategory,
									})
								}
								onUpdate={(data) =>
									updateSubCategoryHandle({
										id: data.info.details.id,
										categoryID: data.info.details.cuisineCategory.id,
										name: data.title,
									})
								}
								onDelete={(data) => delSC(data)}
								buttonProps={{
									size: "large",
									color: "inherit",
									sx: {
										py: "0.32rem",
										textTransform: "capitalize",
										fontWeight: "400",
										fontSize: "1rem",
										borderColor: "#bbb",
										justifyContent: "space-between",
										"& svg": {
											color: "#999",
										},
									},
									fullWidth: true,
									disabled: menuCategory === "unselectable",
								}}
							/>
						</Stack>
					</Stack>
					{/* Cover pcture  */}
					<form
						onSubmit={handleSubmit(createFoodItem)}
						id={"create-food-item"}
					>
						<Stack>
							<Typography variant="tabText">Food Cover Picture</Typography>
							<ImageUploader
								onChange={async (file) => createFoodImageUpload(file)}
								onDelete={async (file) => deleteFood()}
							/>
						</Stack>
						{/* Add menu item */}
						<Stack
							direction="column"
							width="100%"
							rowGap={1}
						>
							<Typography
								variant="cardHeader"
								color="initial"
							>
								Food Name
							</Typography>
							<CustomiseInput
								placeholder="Enter your cuisine name"
								{...register("Name")}
							/>
						</Stack>
						<Stack
							direction={{ xs: "column", md: "row" }}
							justifyContent={"center"}
							alignItems="center"
							columnGap={1}
							rowGap={2}
						>
							<Stack
								direction="column"
								width="100%"
								rowGap={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Price
								</Typography>

								<CustomiseInput
									placeholder="Enter price"
									{...register("Price")}
								/>
							</Stack>
							<Stack
								direction="column"
								width="100%"
								rowGap={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Person
								</Typography>

								<CustomiseInput
									placeholder="Enter person"
									{...register("Person")}
								/>
							</Stack>
						</Stack>
						{/* Cencel or submit data */}
						<Stack
							direction="row"
							width="100%"
							columnGap={1}
							rowGap={1}
						>
							<Button
								fullWidth
								color="secondary"
								variant="contained"
								onClick={() => {
									setOpenDrawer(!openDrawer);
									cancelAddMenu();
									reset();
								}}
								type="button"
							>
								cancel
							</Button>
							<Button
								fullWidth
								bgcolor="secondary"
								type={"submit"}
								form={"create-food-item"}
								variant="contained"
							>
								submit
							</Button>
						</Stack>
					</form>
				</Box>
			</Drawer>
		</>
	);
};

export default RestaurantProfileMenu;

// Menu Edit Drawer
const EditMenuDrawer = ({ data, categoryList, subCategoryData }) => {
	// use Context
	const { userInfo } = useContext(userContext);
	const snack = useContext(snackContext);
	// states
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [menuCategory, setMenuCategory] = React.useState(
		data.cuisineCatgory.id
	);
	const [menuSubCategory, setMenuSubCategory] = React.useState(
		data.cuisineSubCategory.id
	);
	const [subCategoryListToShow, setSubCategoryListToShow] = React.useState([]);
	const [imgBlob, setImgBlob] = React.useState();
	// For handle form (react-hook-form)
	const { handleSubmit, reset, register, setValue } = useForm();
	const [openCancel, setOpenCancel] = React.useState(false);
	const handleCancel = () => setOpenCancel(!openCancel);

	const getBlob = async (url) => {
		const res = await fetch(url);
		const blob = await res.blob();
		const objectURL = URL.createObjectURL(blob);
		return {
			preview: objectURL,
			blob: blob,
			type: blob.type,
			size: blob.size,
			name: url,
			path: url,
		};
	};

	const setImage = async () => {
		const blb = await getBlob(
			`https://api.reserveitbd.com/api/restaurant-profile/${userInfo.VendorId}/menu/image/${data.image}`
		);
		blb.name = data.foodName;
		setImgBlob(blb);
		const tempFile = new File([blb.blob], blb.name, {
			type: blb.type,
			lastModified: new Date().getTime(),
		});
		setValue("Image", tempFile);
	};

	React.useEffect(() => {
		setImage();
	}, []);

	// for menu category change
	const handleMenuCategoryChange = (event) => {
		setMenuSubCategory("unselectable");
		setValue("CuisineSubCategory", null);
		setMenuCategory(event.target.value);
	};

	// sub category change
	const handleSubCategoryChange = (e) => {
		setMenuSubCategory(e.target.value);
	};

	React.useEffect(() => {
		if (subCategoryData) {
			var tempList = subCategoryData.data.filter(
				(d) => d.cuisineCategory.id === menuCategory
			);
			setSubCategoryListToShow(tempList);
		}
	}, [menuCategory]);

	// upload menu image
	const createFoodImageUpload = (file) => {
		// const tempFile = [file];
		setValue("Image", file);
		return true;
	};
	// delete fontLanguageOverride
	const deleteFood = () => {
		setImgBlob();
		setValue("Image", undefined);
		return true;
	};

	// UPDATE FOOD meun item !!
	const { mutateAsync: updateFood } = useCreateAndUpdateFoodItem();

	const updateFoodItem = async (formData) => {
		console.log(formData);
		const res = await responseHandler(
			() =>
				updateFood({
					id: userInfo.VendorId,
					data: {
						...formData,
						ID: data.id,
					},
				}),
			201
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Food Item Uploaded Successfully!!");
			snack.handleClick();
			setOpenDrawer(false);
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	// DELETE food menu item
	const { mutateAsync: deleteFoodItem, error, isError } = useDeleteFoodItem();

	const deleteFoodMenuItem = async () => {
		// const response = await deleteFoodItem({ id: data.id });
		const res = await responseHandler(
			() => deleteFoodItem({ id: data.id, vendorId: userInfo.VendorId }),
			200
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Food Item Deleted Successfully!!");
			snack.handleClick();
			setOpenDrawer(false);
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	React.useEffect(() => {
		console.log(data);
	}, [openDrawer]);

	return (
		<>
			<Paper sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)" }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems={"center"}
					sx={{
						position: "relative",
						height: "100%",
					}}
				>
					<Stack
						direction={"column"}
						spacing={1}
						padding={1.5}
						sx={{ flex: 1 }}
					>
						<Typography variant="cardHeader">
							{data.cuisineCatgory.name}
						</Typography>
						<Typography variant="cardHeader">
							{data.cuisineSubCategory.name}
						</Typography>
						<Typography variant="cardLocation">
							{data.person} &times; {data.foodName} | {data.price}৳
						</Typography>
					</Stack>
					<Stack
						direction={"row"}
						sx={{
							position: "relative",
							flex: 1,
							justifyContent: "flex-end",
							height: "100%",
						}}
					>
						<Avatar
							src={`${backendURL}/restaurant-profile/${userInfo.VendorId}/menu/image/${data.image}`}
							alt={data.foodName}
							sx={{
								position: "relative",
								height: "107px",
								// maxHeight: "107px",
								width: "100%",
								maxWidth: "200px",
								objectFit: "cover",
								borderTopRightRadius: "4px",
								borderBottomRightRadius: "4px",
								borderRadius: 0,
							}}
						/>
						<Fab
							size={"small"}
							onClick={() => setOpenDrawer(!openDrawer)}
							color={"white"}
							sx={{
								position: "absolute",
								fontSize: "1rem",
								opacity: 0.5,
								top: 2,
								right: 2,
								"&:hover": {
									opacity: 1,
									transition: "opacity 0.6s",
								},
							}}
						>
							<FiEdit2 />
						</Fab>
					</Stack>
				</Stack>
			</Paper>
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={() => setOpenDrawer(!openDrawer)}
				sx={{ width: "50rem" }}
				PaperProps={{ sx: { width: { xs: "90%", md: "80%", lg: "480px" } } }}
				component={"form"}
				onSubmit={handleSubmit(updateFoodItem)}
			>
				<Stack
					direction={"row"}
					justifyContent="space-between"
					alignItems="center"
					sx={{ bgcolor: "#F8F8F8", p: 2 }}
				>
					<Stack>
						<Typography variant="header1">{data.foodName}</Typography>
						<Typography variant="cardLocation1">
							Update Food Item for Your Restaurant
						</Typography>
					</Stack>
					<IconButton
						// Close Drawer Icons
						onClick={() => setOpenDrawer(!openDrawer)}
						sx={{
							mr: 1,
							my: { xs: 1, sm: 1.5 },
							backgroundColor: "#fff",
							color: "#F26B8C",
						}}
						size={"small"}
					>
						<MdClose />
					</IconButton>
				</Stack>
				<Stack sx={{ p: 2 }}>
					<Typography
						variant="tabText"
						sx={{ my: 2 }}
					>
						Menu
					</Typography>
					<Paper elevation={5}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems={"flex-start"}
						>
							<Stack
								direction={"column"}
								spacing={1}
								padding={1.5}
							>
								<Typography variant="cardHeader">
									{data.cuisineCatgory.name}
								</Typography>
								<Typography variant="cardHeader">
									{data.cuisineSubCategory.name}
								</Typography>
								<Typography variant="cardLocation">
									{data.person} &times; {data.foodName} | {data.price}৳
								</Typography>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
				<Stack
					direction={{ xs: "column", sm: "column", md: "row" }}
					justifyContent={{ sm: "center", md: "space-between" }}
					alignItems={{ sm: "center", md: "flex-end" }}
					spacing={{ sm: "2", md: "1" }}
					columnGap={1}
					rowGap={1.5}
					sx={{
						p: 2,
						"& > *": {
							flexGrow: 1,
						},
					}}
				>
					<Stack
						spacing={1}
						flexDirection={"column"}
						alignItems="flex-start"
						justifyContent="center"
						width={"100%"}
					>
						<Typography variant="legend">Select Category</Typography>
						<Select
							value={menuCategory}
							onChange={handleMenuCategoryChange}
							size={"small"}
							sx={{ px: 1, width: "100%" }}
						>
							<MenuItem
								disabled
								value={"unselectable"}
							>
								Select Category
							</MenuItem>
							{categoryList?.data.map((cat) => (
								<MenuItem
									key={cat.id}
									value={cat.id}
								>
									{cat.name}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack
						spacing={1}
						flexDirection={"column"}
						alignItems="flex-start"
						justifyContent="center"
						width={"100%"}
					>
						<Typography variant="legend">Select Sub Category</Typography>
						<Select
							size={"small"}
							value={menuSubCategory}
							{...register("CuisineSubCategory")}
							onChange={handleSubCategoryChange}
							sx={{ px: 1, width: "100%" }}
							disabled={menuCategory === "unselectable"}
						>
							<MenuItem
								disabled
								value={"unselectable"}
							>
								Select Sub Category
							</MenuItem>
							{subCategoryListToShow.map((subCat) => (
								<MenuItem
									key={subCat.id}
									value={subCat.id}
								>
									{subCat.name}
								</MenuItem>
							))}
						</Select>
					</Stack>
				</Stack>
				{/* Food cover picture.. */}
				<Stack sx={{ p: 2 }}>
					<Typography
						variant="tabText"
						mb={2}
					>
						Food Cover Picture
					</Typography>
					<ImageUploader
						defaultValue={imgBlob}
						onChange={async (file) => createFoodImageUpload(file)}
						onDelete={async (file) => deleteFood()}
					/>
				</Stack>

				<Stack
					p={2}
					spacing={2}
				>
					<Stack>
						<Typography
							variant="cardHeader"
							color="initial"
						>
							Food Name
						</Typography>

						<CustomiseInput
							defaultValue={data.foodName}
							{...register("Name")}
							placeholder={"Enter Food Name"}
						/>
					</Stack>
					<Stack
						direction={{ xs: "column", md: "row" }}
						justifyContent={"center"}
						alignItems="center"
						columnGap={3}
						rowGap={2}
					>
						<Stack
							direction="column"
							width="100%"
						>
							<Typography
								variant="cardHeader"
								color="initial"
							>
								Price
							</Typography>

							<CustomiseInput
								placeholder="Enter Price"
								defaultValue={data.price}
								{...register("Price")}
							/>
						</Stack>
						<Stack
							direction="column"
							width="100%"
						>
							<Typography
								variant="cardHeader"
								color="initial"
							>
								Person
							</Typography>

							<CustomiseInput
								placeholder="Enter Person"
								defaultValue={data.person}
								{...register("Person")}
							/>
						</Stack>
					</Stack>
					<Stack
						direction={{ xs: "column", sm: "row", md: "row" }}
						width="100%"
						columnGap={1}
						rowGap={1.5}
					>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							type="submit"
						>
							Update
						</Button>
						<Button
							fullWidth
							color={"error"}
							variant="contained"
							onClick={handleCancel}
						>
							Delete
						</Button>
					</Stack>
				</Stack>
				<AreYouSure
					handleFunc={deleteFoodMenuItem}
					cancelOpen={openCancel}
					handleOpen={handleCancel}
				/>
			</Drawer>
		</>
	);
};

/**
 *
 * developed by noman :)
 * using this component VA can add a new menu item, edit existing menu item.
 * munu item collect from api call.
 *
 * last commit
 */
